import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { client } from '../config/apollo';
import { PageHead } from './PageHead';

export const RootLayout: React.FC = ({ children }) => (
  <ApolloProvider client={client}>
    <PageHead />
    {children}
  </ApolloProvider>
);
