import React from 'react';
import { Helmet } from 'react-helmet';

export const PageHead: React.FC = () => (
  <Helmet>
    <title>Send Eats - Rates</title>
    <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
    <link rel="shortcut icon" href="/favicon.png" />
    <link rel="apple-touch-icon" href="/favicon.png" />
    <link rel="apple-touch-icon" href="/favicon.png" />
    <link rel="icon" href="/favicon.png" />
    <link rel="icon" href="/favicon.png" />
    <link rel="icon" href="/favicon-150x150.png" sizes="32x32" />
    <link rel="icon" href="/favicon-300x300.png" sizes="192x192" />
    <link rel="apple-touch-icon" href="/favicon-300x300.png" />
    <meta name="msapplication-TileImage" content="/favicon-300x300.png" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
  </Helmet>
);
